export const frameSize = {
  id: 'frameSize',
  key: 'frame_size',
  label: 'Frame size',
  mandatory: true,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'SelectMultipleFilter',
  translationGroup: 'frame_size',
  queryParamNames: ['pub_frameSize'],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'xs', value: 'xs', label: 'XS' },
      { key: 's', value: 's', label: 'S' },
      { key: 'm', value: 'm', label: 'M' },
      { key: 'l', value: 'l', label: 'L' },
      { key: 'xl', value: 'xl', label: 'XL' },
      { key: 'xxl', value: 'xxl', label: 'XXL' },
      { key: '42', value: '42', label: '42' },
      { key: '43', value: '43', label: '43' },
      { key: '44', value: '44', label: '44' },
      { key: '45', value: '45', label: '45' },
      { key: '46', value: '46', label: '46' },
      { key: '47', value: '47', label: '47' },
      { key: '48', value: '48', label: '48' },
      { key: '49', value: '49', label: '49' },
      { key: '50', value: '50', label: '50' },
      { key: '51', value: '51', label: '51' },
      { key: '52', value: '52', label: '52' },
      { key: '53', value: '53', label: '53' },
      { key: '54', value: '54', label: '54' },
      { key: '55', value: '55', label: '55' },
      { key: '56', value: '56', label: '56' },
      { key: '57', value: '57', label: '57' },
      { key: '58', value: '58', label: '58' },
      { key: '59', value: '59', label: '59' },
      { key: '60', value: '60', label: '60' },
      { key: '61', value: '61', label: '61' },
      { key: '62', value: '62', label: '62' },
      { key: '63', value: '63', label: '63' },
      { key: '64', value: '64', label: '64' },
    ],
  },
};
